.order--input-cursor {
    cursor: pointer;
}

.rdrDefinedRangesWrapper {
    display: none;
}

.pending-style,
.verification-style,
.processing-style .paid-style,
.unpaid-style,
.failed-style {
    font-size: 12px;
    padding: 0 18px;
    line-height: 24px;
    display: inline-block;
    border-radius: 12px;
    width: fit-content;
}

.current-style {
    background-color: #e6f1f8;
}

.due-style {
    background-color: #e6f1f8;
}

.verification-style {
    background-color: #f8f8e8;
}

.pending-style {
    background-color: #f8f8e8;
}

.processing-style {
    background-color: #f8f8e8;
}

.paid-style {
    background-color: #e6f8e6;
}

.failed-style {
    background-color: #f8e6e6;
}

.invoice-float-btn {
    .product-selected-item {
        width: 228px;
        height: 39px;
        border-radius: 2px;
        box-shadow: 0 0 20px 0 rgba(0, 0, 0, 0.45);
        background-color: #ed2c2b;
        border: none;
        font-size: 13px;
        font-weight: 600;
        font-stretch: normal;
        font-style: normal;
        line-height: 1.08;
        letter-spacing: -0.1px;
        text-align: center;
        color: #fff;
    }

    .product-item-selected-row {
        bottom: 12vh;
        z-index: 1;
        left: 0px;
        text-align: right;
        padding-right: 45px;
    }

    .products__quantity {
        position: absolute;
        width: 33px;
        height: 33px;
        box-shadow: 0 0 13px 0 rgba(0, 0, 0, 0.21);
        background-color: var(--white);
        border-radius: 50%;
        right: -15px;
        top: -15px;
        color: #ed2c2b;
        font-size: 12px;
        font-weight: 600;
    }
}

.modal_order_table {
    .order_table {
        margin-left: 22px;
        margin-right: 22px;
        padding: 13px 0;
        border-top: solid 1px #f0f0f0;
    }

    .order_table_header {
        font-size: 13px;
        font-weight: bolder;
        font-stretch: normal;
        font-style: normal;
        line-height: normal;
        letter-spacing: 0.5px;
        padding-bottom: 13px;
        color: #645a5b;
        border-bottom: solid 1px #f0f0f0;
        display: grid;
        grid-template-columns: 2fr 1fr 1fr 1fr 1fr;

        div:nth-child(2),
        div:nth-child(3),
        div:nth-child(4) {
            padding-left: 15px;
        }
    }

    .product_details_checkout {
        margin-top: 13px;
        display: grid;
        grid-template-columns: 2fr 1fr 1fr 1fr 1fr;
        align-items: center;
    }

    .product_detail_1 {
        border-right: solid 1px #ebebeb
    }

    .product_detail_2 {
        font-size: 14px;
        font-weight: 600;
        font-stretch: normal;
        font-style: normal;
        line-height: 1.43;
        letter-spacing: normal;
        color: #0069a7;
        border-right: solid 1px #ebebeb;
        height: 100%;
        display: flex;
        align-items: center;
        padding-left: 15px;
    }

    .product_detail_3 {
        display: flex;
        flex-direction: column;
        border-right: solid 1px #ebebeb;
        height: 100%;
        justify-content: center;
        align-items: center;
        padding-left: 15px;

        span:nth-child(1) {
            opacity: 0.8;

            font-size: 11px;
            font-weight: 500;
            font-stretch: normal;
            font-style: normal;
            line-height: normal;
            letter-spacing: 0.92px;
            color: #645a5b;
        }

        span:nth-child(2) {

            font-size: 14px;
            font-weight: 500;
            font-stretch: normal;
            font-style: normal;
            line-height: 1.43;
            letter-spacing: normal;
            color: #3d3839;
        }
    }

    .product_detail_4 {
        font-size: 14px;
        font-weight: 600;
        font-stretch: normal;
        font-style: normal;
        line-height: normal;
        letter-spacing: 0.78px;
        color: #3d3839;
        border-right: solid 1px #ebebeb;
        height: 100%;
        display: flex;
        align-items: center;
        padding-left: 15px;

        p {
            margin: 0;
        }
    }

    .product_detail_5 {
        font-size: 14px;
        font-weight: 600;
        font-stretch: normal;
        font-style: normal;
        line-height: normal;
        letter-spacing: 0.78px;
        text-align: right;
        color: #3d3839;
        height: 100%;
        display: flex;
        align-items: center;
        justify-content: end;

        p {
            margin: 0;
        }
    }

    .product-delivered {
        font-size: 14px;
        font-weight: normal;
        font-stretch: normal;
        font-style: normal;
        line-height: normal;
        letter-spacing: 0.78px;
        color: #3d3839;
        border-bottom: solid 1px #f0f0f0;
        padding: 5px 0;
    }

    .product-delivered .days {
        font-weight: bolder;
        color: #645a5b;
    }
}

.modal-full {
    .total_checkout {
        padding: 0 13px;
        display: flex;
        justify-content: end;
    }

    .total_checkout_heading {
        margin: 8px 81px 12px 0;
    }

    .total_checkout_heading,
    .total_checkout_amount {
        font-size: 20px;
        font-weight: bolder;
        font-stretch: normal;
        font-style: normal;
        line-height: 1.4;
        letter-spacing: normal;
        color: #3d3839;
    }

    .total_checkout_amount {
        margin: 8px 10px 12px 0;
    }
}

.modal-full {

    .pending-style {
        font-size: 12px;
        padding: 0 18px;
        line-height: 24px;
        display: inline-block;
        border-radius: 12px;
        width: fit-content;
        background-color: #f8f8e8;
        ;
    }

    .paid-style {
        font-size: 12px;
        padding: 0 18px;
        line-height: 24px;
        display: inline-block;
        border-radius: 12px;
        width: fit-content;
        background-color: #e6f8e6;
    }

    .due-style {
        font-size: 12px;
        padding: 0 18px;
        line-height: 24px;
        display: inline-block;
        border-radius: 12px;
        width: fit-content;
        background-color: #e6f1f8;
    }

    .current-style {
        font-size: 12px;
        padding: 0 18px;
        line-height: 24px;
        display: inline-block;
        border-radius: 12px;
        width: fit-content;
        background-color: #e6f1f8;
    }

    .failed-style {
        font-size: 12px;
        padding: 0 18px;
        line-height: 24px;
        display: inline-block;
        border-radius: 12px;
        width: fit-content;
        background-color: #f8e6e6;
    }
}

.card_type {
    cursor: pointer;
    width: 36px;
    margin-right: 10px;
    height: auto;
    border: 1px solid #dcdcdc;
}

.active-invoice-btn {
    background: #ed2c2b;
    color: #fff;
    padding: 5px 10px;
    border: unset;
    font-weight: 600;
}

.invoice-btn {
    background: #fbb6b5;
    color: #fff;
    padding: 5px 10px;
    border: unset;
    font-weight: 600;
}

.invoice_card {
    margin-top: 30px;

    h3 {
        font-size: 18px;
    }

    .card__1 {
        padding-bottom: 35px;
    }

    .radio_card {
        margin-right: 15px;
        width: 16px;
        height: 16px;
        cursor: pointer;
    }
}

.dashboard-container-wrap {
    // .container {
    //     max-width: 1380px;
    //     // padding-right: 0;
    // }

    // nav menu
    .account-nav {
        padding-top: 15px;
        box-shadow: 0 0 11px 0 rgba(0, 0, 0, 0.02);
        border: solid 1px rgba(227, 227, 227, 0.57);
        border-top: 0;

        .account-nav__list {
            font-size: 13px;
            font-weight: 600;
            line-height: 2.3;

            @media only screen and (max-width:1200px) {
                font-size: 12px;

            }

            .account-nav__item {
                margin: 0 0 12.7px;
                width: 100%;

                &.logout-nav {
                    margin-bottom: 3px;
                }

                a {
                    color: $black-64;
                    padding: 5px 1.5rem;

                    &.active {
                        background-color: transparent;
                        font-weight: 800;
                        color: $black-3d;
                        box-shadow: 3px 0 #e52727 inset;

                        @media only screen and (max-width:1200px) {
                            border-bottom: 3px solid #e52727;
                            box-shadow: unset;
                        }

                    }

                    &.logout-btn {
                        color: $heading-color;
                        font-weight: normal;
                        text-decoration: underline;
                    }
                }

            }
        }
    }

    // .new-logout-btn{
    //     color: #645a5b;
    //     padding: 5px 1.5rem;
    //     cursor: pointer;
    // }
    h4 {
        &.account-nav__title {
            padding: 15px 10px 15px 24px;
            display: flex;
            align-items: center;
            background-color: #fff;
            box-shadow: 0 0 11px 0 rgba(0, 0, 0, 0.02);
            border: solid 1px rgba(227, 227, 227, 0.57);

            .user-name-right {
                margin-left: 12px;
                font-size: 13px;
                line-height: 14px;
                font-weight: 600;

                span {
                    display: block;
                    font-size: 12px;
                    font-weight: 400;
                }
            }
        }
    }

    // right container
    .card {
        box-shadow: 0 0 11px 0 rgba(0, 0, 0, 0.02);
        border: solid 1px rgba(227, 227, 227, 0.57);

        .card-header {
            @media only screen and (max-width:767px) {
                margin-bottom: 15px;
                padding: 15px 15px;
            }

            .title_holder {
                display: flex;
                justify-content: space-between;
                margin-bottom: 15px;

                h5 {
                    color: $black-64;
                    font-size: 20px;
                    line-height: 20px;
                    letter-spacing: 1px;
                    font-weight: 600;

                    @media only screen and (max-width:767px) {
                        font-size: 18px;
                    }
                }

                .showing_result {
                    font-size: 13px;
                    color: $black-64;

                    span {
                        font-weight: 800;
                    }
                }
            }

            .search-container-top {
                display: flex;
                justify-content: space-between;

                .filter_right {
                    width: 50%;

                    @media only screen and (max-width:767px) {
                        width: 100%;
                    }
                }

                @media only screen and (max-width:1200px) {
                    flex-wrap: wrap;

                    .filter_left

                    .filter_left {
                        margin-bottom: 15px;
                    }
                }

                .search-inner {
                    display: flex;
                    justify-content: flex-start;
                    font-size: 14px;
                    color: #606060;

                    @media only screen and (max-width:767px) {
                        font-size: 13px;
                    }

                    .filterby {
                        width: 152px;
                        border: 1px solid rgba(100, 88, 90, 0.4);
                        padding: 0 0 0 10px;
                        box-sizing: border-box;
                        height: 34px;
                        overflow: hidden;
                        border-top-left-radius: 1px;
                        border-bottom-left-radius: 1px;

                        .filter-select {
                            height: 32px;
                            border: 0;
                            background-color: #fff;
                            font-weight: 600;
                            line-height: 2;
                            color: $black-64;
                            padding-left: 7px;
                            width: 75px;
                            position: relative;
                            background-position-y: 13px;
                            cursor: pointer;

                            &:focus-visible {
                                outline: none;
                                border: none;
                            }
                        }
                    }

                    .newfilter {
                        width: 152px;
                        height: 34px;
                    }

                    .filterby_search {
                        width: 171px;
                        position: relative;

                        @media only screen and (max-width:767px) {
                            width: 158px;
                        }

                        .search-account {
                            border: 1px solid rgba(100, 88, 90, 0.4);
                            border-left: 0;
                            height: 34px;
                            width: 100%;
                            box-sizing: border-box;
                            padding-left: 13px;
                            border-top-right-radius: 1px;
                            border-bottom-right-radius: 1px;

                            &:focus {
                                border: 1px solid rgba(100, 88, 90, 0.4) !important;
                                border-left: 0 !important;
                            }
                        }

                        .filter-btn {
                            position: absolute;
                            right: 4px;
                            top: 9px;
                            border: 0;
                            background: none;

                            span {
                                color: #8a8282;
                                transition: all 0.3s ease-in-out;

                                &:hover {
                                    color: #000;
                                }
                            }
                        }
                    }

                    .filter_date {
                        display: flex;
                        justify-content: space-between;
                        width: 370px;

                        @media only screen and (max-width:1200px) {
                            flex-wrap: wrap;
                            justify-content: flex-start;
                        }

                        .date-field {
                            border: 1px solid rgba(100, 88, 90, 0.4);
                            max-width: 145px;
                            height: 34px;
                            border-radius: 1px;
                            padding: 0 5px 0 10px;
                            font-size: 13px;

                            @media only screen and (max-width:1200px) {
                                max-width: 157px;
                            }

                            @media only screen and (max-width:767px) {
                                max-width: 150px;
                                font-size: 12px;
                            }

                            &:focus {
                                border: 1px solid rgba(100, 88, 90, 0.4) !important;
                            }
                        }

                        span {
                            &:nth-child(1) {
                                line-height: 1.3;
                                width: 60px;

                                @media only screen and (max-width:1200px) {
                                    width: 100%;
                                    margin-bottom: 5px;
                                }
                            }

                            @media only screen and (max-width:1200px) {
                                &:nth-child(3) {
                                    margin-left: 10px;
                                }
                            }
                        }
                    }
                }
            }
        }
    }

    .card-table {
        .order--item {
            width: 110px;
        }

        .order---status {
            // min-width: 126px;
            word-break: normal;
        }

        .table-account {
            @media only screen and (max-width:990px) {
                table {
                    width: 800px;
                }
            }

            th {
                font-size: 13px;

                span {
                    position: relative;
                }

                .sorting {
                    cursor: pointer;
                }
            }

            td {
                padding: 12px 10px !important;
                font-size: 14px;

                .item-counts {
                    line-height: 2;
                    color: $black-64;
                    width: 100%;
                    position: relative;
                }

                &:first-child {
                    font-weight: 600;

                    a {
                        color: $heading-color;
                    }
                }
            }

            .item-arrow {
                font-size: 16px;
                color: $black-64;
                padding-left: 6px;
                position: relative;
                top: -2px;
                cursor: pointer;
                padding-bottom: 10px;

                &:hover {
                    .pop-item {
                        display: block;
                    }
                }

                .pop-item {
                    position: absolute;
                    width: 208px;
                    height: 105px;
                    background-color: #fff;
                    box-shadow: 0 0 4px 0 rgba(0, 0, 0, 0.25);
                    right: -15px;
                    top: 30px;
                    z-index: 10;
                    padding: 10px 0;
                    display: none;

                    .pop-item-head,
                    .pop-item-body {
                        display: flex;
                        justify-content: flex-start;
                        padding: 0 13px;
                        font-size: 13px;
                        letter-spacing: 0.5px;
                        font-weight: bold;
                        color: $black-64;

                        span {
                            &:first-child {
                                width: 60%;
                            }

                            &:last-child {
                                text-align: center;
                                width: 30px;
                            }
                        }
                    }

                    .pop-item-head {
                        border-bottom: 1px solid #f0f0f0;
                        padding-bottom: 6px;
                        margin-bottom: 6px;
                    }

                    .pop-item-body {
                        font-size: 14px;
                        font-weight: 400;
                        line-height: 26px;
                    }

                    &:after {
                        content: '';
                        border: solid #e3e1e1;
                        border-width: 0 1px 1px 0;
                        display: inline-block;
                        padding: 5px;
                        position: absolute;
                        top: -7px;
                        right: 14px;
                        transform: rotate(-135deg);
                        background-color: #fff;
                    }
                }
            }
        }
    }

    .paid-style {
        font-size: 12px;
        padding: 0 18px;
        line-height: 24px;
        display: inline-block;
        border-radius: 12px;
        background-color: #e6f8e6;
    }

    .unpaid-style {
        font-size: 12px;
        padding: 0 18px;
        line-height: 24px;
        display: inline-block;
        border-radius: 12px;
        background-color: #e6f1f8;
    }

    .failed-style {
        font-size: 12px;
        padding: 0 18px;
        line-height: 24px;
        display: inline-block;
        border-radius: 12px;
        background-color: #f8e6e6;
    }

    .shipped-style {
        font-size: 12px;
        padding: 0 18px;
        line-height: 24px;
        display: inline-block;
        border-radius: 12px;
        background-color: #e6f1f8;
    }

    .delivered-style {
        font-size: 12px;
        padding: 0 18px;
        line-height: 24px;
        display: inline-block;
        border-radius: 12px;
        background-color: #e1f3dc;
    }

    // Account details //
    .edit-btn-account {
        font-size: 14px;
        font-weight: 600;
        letter-spacing: 1px;
        color: #ffffff;
        background-color: $black-64;
        border: 0;
        outline: none;
        padding: 5px 20px;
        margin-top: 8px;

        &:hover {
            opacity: 0.9;
        }

        @media only screen and (max-width:767px) {
            margin-top: 0;
        }
    }

    .card {
        &.account-detail-wrap {
            .card-header {
                border-bottom: solid 1px #f0f0f0;
                margin-bottom: 24px;
                padding: 16px 30px 20px;

                // height: 68px;
                @media only screen and (max-width:767px) {
                    margin-bottom: 15px;
                    // height: 60px;
                    padding: 15px 15px;
                }

                .title_holder {
                    margin-bottom: 0;
                    align-items: center;
                    height: 31px;

                    h5 {
                        margin: 0;
                    }
                }
            }

            .right-body-account {
                padding: 0 30px 30px;

                @media only screen and (max-width:767px) {
                    padding: 0 15px 15px;
                }

                h6 {
                    font-size: 16px;
                    line-height: 26px;
                    color: $heading-color;
                    font-weight: 600;
                    margin-bottom: 15px;
                }

                .acc-detail-block-outer {
                    margin-bottom: 26px;

                    .account-detail-block {
                        display: flex;
                        justify-content: space-between;
                        margin-bottom: 15px;

                        @media only screen and (max-width:767px) {
                            flex-direction: column;
                        }

                        &:last-child {
                            margin-bottom: 0;
                        }

                        .block-left,
                        .block-right {
                            width: 48%;

                            @media only screen and (max-width:767px) {
                                width: 100%;
                            }

                            .contact-line-1 {
                                margin-bottom: 15px;

                                .bold-label {
                                    color: $black-3d;
                                    font-size: 16px;
                                    margin-bottom: 14px;
                                    font-weight: bold;
                                }

                                &:last-child {
                                    margin-bottom: 0;
                                }
                            }

                            label {
                                font-size: 12px;
                                letter-spacing: 1px;
                                color: $black-64;
                                font-weight: 500;
                                text-transform: uppercase;
                                opacity: 0.8;
                            }

                            .label-value {
                                font-size: 15px;
                                line-height: 20px;
                                color: $black-3d;
                                font-weight: 500;
                            }
                        }

                        @media only screen and (max-width:767px) {
                            .block-left {
                                margin-bottom: 15px;
                            }
                        }
                    }
                }
            }

            .right-body-account {
                &.edit-acc-details {
                    label {
                        font-size: 14px;
                        font-weight: 600;
                        line-height: 1.86;
                        text-transform: unset;
                        color: $black-64;
                        opacity: 1;
                        letter-spacing: 0;
                    }

                    .label-value {
                        display: flex;
                        justify-content: space-between;

                        &.check-value {
                            margin-top: 10px;

                            .check__box__gap {
                                margin-bottom: 0;

                                .flex-checkbox {
                                    align-items: center;
                                    margin-bottom: 3px;

                                    .checkbox-name {
                                        margin-bottom: 0;
                                        margin-top: 2px;
                                        margin-left: 14px;
                                        font-weight: normal;
                                    }
                                }
                            }
                        }

                        .input-style-detail {
                            width: 100%;
                            height: 45px;
                            padding-left: 15px;
                            padding-right: 5px;
                            font-size: 14px;
                            line-height: 20px;
                            color: $black-3d;
                            font-weight: 600;
                            border: 1px solid $black-64;

                            &:focus-visible {
                                outline: none;
                            }
                        }

                        .mobile--input {
                            width: 100%;
                            color: $black-3d;
                            font-weight: 600;
                            font-size: 14px;
                            opacity: 1;
                            border: 1px solid $black-64;

                            &:focus-visible {
                                outline: none;
                            }
                        }

                        .fName,
                        .lName {
                            width: 48%;
                        }
                    }

                    .acc-detail-block-outer {
                        .account-detail-block {
                            margin-bottom: 20px;
                        }
                    }
                }

                // change password
                &.change-password-wrap {
                    min-height: 608px;

                    @media only screen and (max-width:1200px) {
                        min-height: 585px;
                    }

                    @media only screen and (max-width:990px) {
                        min-height: auto;
                    }

                    .change-password-inner {
                        font-size: 14px;
                        line-height: 26px;
                        color: $black-64;
                        font-weight: 600;
                        max-width: 380px;

                        .line1 {
                            margin-bottom: 19px;

                            &:last-child {
                                margin-bottom: 0;
                            }

                            .input-style-detail {
                                font-weight: 400;
                                padding-left: 16px;
                                letter-spacing: 0.29px;
                            }

                            .filter_date {
                                display: flex;
                                justify-content: space-between;

                                .r_date_left,
                                .r_date_right {

                                    // width: 48%;
                                    .datepicker-container img {
                                        right: 14px;
                                    }

                                    span {
                                        &:nth-child(1) {
                                            margin-bottom: 5px;
                                            display: block;
                                        }
                                    }

                                    .mobile--input {
                                        width: 100%;
                                        border-color: $black-64;
                                        opacity: 1;
                                        font-size: 14px;
                                        letter-spacing: 0.29px;
                                    }
                                }

                                @media only screen and (max-width:480px) {
                                    flex-direction: column;
                                }
                            }
                        }

                        .detail-btns {
                            margin-top: 25px;
                        }

                        &.reports-inner {
                            .line1 {
                                .label-value {
                                    .mobile--input {
                                        &.select_input {
                                            option {
                                                color: black;
                                            }

                                            option[value=""] {
                                                font-weight: normal;
                                            }

                                            &:invalid {
                                                font-weight: normal;
                                            }
                                        }
                                    }
                                }
                            }

                            .detail-btns {
                                margin-top: 20px;
                            }
                        }
                    }
                }
            }
        }

        &.preferred-product-outer {
            // padding-bottom: 32px;
            padding-top: 24px;

            @media only screen and (max-width:767px) {
                background: none;
                padding: 0;
                border: 0;
                box-shadow: unset;
            }

            .top-tabs-preferred-products {
                @media only screen and (max-width:767px) {
                    border-bottom: 1px solid #ebebeb;
                    padding-top: 15px;
                    background: #ffffff;
                    box-shadow: 0 0 11px 0 rgba(0, 0, 0, 0.02);
                    border: solid 1px rgba(227, 227, 227, 0.57);
                }

                ul {
                    list-style: none;
                    padding: 0;
                    margin: 0 0 0 32px;
                    display: flex;

                    @media only screen and (max-width:767px) {
                        margin-left: 10px;
                        margin-right: 10px;
                    }

                    li {
                        font-size: 14px;
                        line-height: 20px;
                        color: rgba(131, 123, 124, 1);
                        cursor: pointer;
                        padding: 0 16px 11px;
                        position: relative;
                        font-weight: 500;

                        @media only screen and (max-width:767px) {
                            text-align: center;
                        }

                        &.active {
                            color: $black-3d;
                            background: none;

                            &:after {
                                width: 100%;
                            }
                        }

                        &:after {
                            content: '';
                            position: absolute;
                            bottom: 0;
                            height: 3px;
                            width: 0;
                            background-color: #ed2c2b;
                            left: 0;
                            right: 0;
                            margin: 0 auto;
                            transition: all 0.3s ease;
                        }

                        &:last-child {
                            margin-left: 30px;
                        }
                    }
                }
            }
        }
    }

    .edit-detail-tax-left {
        width: 35.5% !important;

        @media only screen and (max-width:767px) {
            width: 100% !important;
        }
    }

    .edit-detail-tax-right {
        width: 60.3% !important;

        @media only screen and (max-width:767px) {
            width: 100% !important;
        }

        .pdf-icon-wrap {
            margin-top: 0;
        }
    }

    // Location management
    .location-wrap {
        padding-bottom: 22px !important;
    }

    .card-location {
        margin-top: 10px;
        padding-top: 26px;

        &.location-pending-block {
            background-color: #fff0f0;

            .location-pending-message {
                margin-top: 13px;

                .pending-box {
                    margin-bottom: 0;
                }
            }
        }
    }

    .card-location-header .card-header {
        margin-bottom: 0 !important;
    }

    // manage payment
    .payments-wrap {
        margin-top: 5px;

        // min-height: 603px;
        h5 {
            color: #645a5b;
            font-size: 20px;
            letter-spacing: 1px;
            font-weight: 600;
        }

        @media only screen and (max-width:1200px) {
            min-height: 580px;
        }

        @media only screen and (max-width:990px) {
            min-height: auto;
        }

        .payment-inner {
            display: flex;
            justify-content: space-between;
            flex-wrap: wrap;
            padding: 15px 50px;
            min-height: 100px;

            @media only screen and (max-width:1200px) {
                padding: 15px 0;
            }

            .card-box {
                margin-bottom: 26px;
                border-radius: 7px;
                width: 45%;
                position: relative;
                transition: all 0.4s ease;
                box-shadow: 0 2px 4px 0 #cfd7df;
                min-height: 200px;
                padding: 24px;
                line-height: 2;

                @media only screen and (max-width:767px) {
                    width: 60%;
                    margin-left: auto;
                    margin-right: auto;
                }

                @media only screen and (max-width:599px) {
                    width: 95%;
                    margin-bottom: 15px;
                }
            }

            .card-box.selectable:hover {
                cursor: pointer;
                box-shadow: 0 10px 20px rgba(0, 0, 0, 0.19), 0 6px 6px rgba(0, 0, 0, 0.23);
            }

            .wrapper-delete {
                display: flex;
                justify-content: space-between;
                align-items: center;
                margin: 0;

                p {
                    font-size: 20px;
                    font-weight: 500;
                    margin: 0;

                    img {
                        margin-right: 10px;
                    }
                }

                .delete-btn {
                    border: none;
                    background: transparent;
                    color: #eaeef2;
                    font-size: 26px;
                }

                .delete-btn:hover {
                    color: #ED5E68;
                }
            }

            .bank-account-name {
                font-size: 20px;
                font-weight: 500;
                margin: 0;
            }

            .card-box-last4 {
                font-family: "PT Mono", Helvetica, sans-serif;
                font-size: 24px;
            }

            .card-box-last4:before {
                content: "**** **** **** ";
                color: #4f4d4d;
                font-size: 20px;
            }

            .wrapper-expiry {
                display: flex;
                justify-content: space-between;
                align-items: center;
            }

            .card-box-expiry {
                font-family: "PT Mono", Helvetica, sans-serif;
                font-size: 18px;
            }

            .card-box.visa {
                background: #4862e2;
                color: #eaeef2;
            }

            .card-box.visa .card-box-last4:before {
                color: #8999e5;
            }

            .card-box.mastercard {
                background: #4f0cd6;
                color: #e3e8ef;
            }

            .card-box.mastercard .card-box-last4:before {
                color: #8a82dd;
            }

            .card-box.bank-account {
                background: #47bfff;
                color: #fafdff;
            }

            .card-box.bank-account .card-box-last4:before {
                color: #99dcff;
            }
        }
    }

    .order-detail-header {

        // top part
        .card-header {
            margin-bottom: 0 !important;
            border: 0 !important;
            height: auto !important;
            padding: 22px 30px 23px !important;

            .title_holder {
                height: auto !important;
                display: block !important;
            }
        }

        .od-header-inner-buttons {
            align-items: center;
            margin-top: 10px;
        }

        .od-header-inner {
            display: flex;
            justify-content: space-between;
            width: 100%;

            @media only screen and (max-width:767px) {
                flex-wrap: wrap;
            }

            .od-header-left,
            .od-header-right {
                @media only screen and (max-width:767px) {
                    width: 100%;
                }

                .od-line1 {
                    font-size: 14px;
                    line-height: 24px;
                    font-weight: normal;
                    color: $black-64;
                    letter-spacing: 0.2px;
                    margin-bottom: 4px;

                    @media only screen and (max-width:767px) {
                        font-size: 13px;
                    }

                    span {

                        &:last-child,
                        &.dollar {
                            font-size: 20px;
                            line-height: 20px;
                            letter-spacing: 1px;
                            font-weight: 600;
                        }

                        &.dollar {
                            font-weight: 400;
                        }
                    }
                }

                .od-line2 {
                    font-size: 14px;
                    line-height: 24px;
                    font-weight: normal;
                    color: $black-64;
                    letter-spacing: 0.2px;
                    margin-bottom: 4px;

                    @media only screen and (max-width:767px) {
                        font-size: 13px;
                    }

                    span {
                        &:last-child {
                            font-weight: 600;
                        }
                    }
                }

                .button-links {
                    font-size: 12px;
                    letter-spacing: 0.8px;
                    line-height: 22px;
                    font-weight: 600;
                    color: $heading-color;
                    position: relative;

                    @media only screen and (max-width:767px) {
                        &:last-child {
                            display: block;
                            margin-top: 6px;
                        }
                    }

                    &:hover {
                        text-decoration: underline;
                    }

                    &:after {
                        content: '';
                        border-right: 1px solid #d7d7d7;
                        height: 100%;
                        margin: 0 15px;
                    }

                    &:last-child {
                        &:after {
                            display: none;
                        }
                    }

                    img {
                        margin-right: 5px;
                        position: relative;
                    }

                    &.export-btn {
                        img {
                            top: -2px;
                        }
                    }
                }
            }

            @media only screen and (max-width:767px) {
                .od-header-right {
                    margin-top: 15px;
                }
            }
        }

        .reorder-btn {
            background-color: $black-64;
            border-radius: 2px;
            width: 105px;
            height: 34px;
            border: none;
            outline: none;
            color: #fff;
            font-size: 12px;
            font-weight: 500;
            letter-spacing: 0.8px;
            transition: all 0.3s ease;
            padding-right: 10px;
            padding-left: 10px;

            &:hover {
                background-color: #000;
            }

            svg {
                margin-right: 5px;

                g {
                    fill: #ffffff;
                }
            }
        }
    }

    // end top part 
    .sub-header-order-detail {
        &.card-location {
            padding-top: 0;
            margin-bottom: 0;

            .card-header {
                height: auto;
                padding: 25px 30px 15px;
                margin-bottom: 0;

                .title_holder {
                    height: auto;
                    display: block;

                    h6 {
                        margin: 0;
                        font-weight: 600;
                    }
                }
            }
        }

        .order-location {
            font-size: 16px;
            line-height: 20px;
            letter-spacing: 0.8px;
            color: $black-64;
            font-weight: 600;
            margin-bottom: 16px;

            span {
                color: $heading-color;
            }
        }

        .order-location-bottom {
            display: flex;
            justify-content: space-between;
            margin-bottom: 15px;

            @media only screen and (max-width:767px) {
                flex-wrap: wrap;
            }

            div {
                border-left: 1px solid #e6e6e6;
                padding-left: 30px;
                width: 28%;

                @media only screen and (max-width:767px) {
                    width: 100%;
                    padding: 0 0 15px 0;
                    border-left: 0;
                    border-bottom: 1px solid #e6e6e6;
                    margin-bottom: 15px;
                }

                &:first-child {
                    border: 0;
                    padding: 0;

                    @media only screen and (max-width:767px) {
                        border-bottom: 1px solid #e6e6e6;
                        padding: 0 0 15px 0;
                        margin-bottom: 15px;
                    }
                }

                @media only screen and (max-width:767px) {
                    &:last-child {
                        border-bottom: 0;
                        margin-bottom: 0;
                        padding-bottom: 0;
                    }
                }
            }

            span {
                display: block;
                font-size: 14px;
                line-height: 26px;
                letter-spacing: 0.2px;
                color: $black-64;

                &.head {
                    font-size: 16px;
                    font-weight: 600;
                    letter-spacing: 0.8px;
                    line-height: 20px;
                    margin-bottom: 12px;
                }
            }
        }

        .shipment-head {
            display: flex;
            justify-content: space-between;
            margin-bottom: 11px;

            h6 {
                font-size: 16px;
                line-height: 20px;
                letter-spacing: 0.8px;
                color: $black-64;
            }

            .view-invoice {
                font-size: 12px;
                font-weight: 600;
                letter-spacing: 0.8px;
                line-height: 22px;
                color: $heading-color;

                &:hover {
                    text-decoration: underline;
                }
            }
        }

        .shipment-detail-wrap {
            display: flex;
            justify-content: space-between;
            font-size: 14px;
            font-weight: normal;
            line-height: 26px;
            letter-spacing: 0.2px;
            color: $black-64;
            margin-bottom: 5px;

            @media only screen and (max-width:767px) {
                flex-wrap: wrap;
            }

            div {
                @media only screen and (max-width:767px) {
                    width: 100%;
                }

                span {
                    font-weight: 600;
                }

                .track-order {
                    font-size: 12px;
                    line-height: 22px;
                    font-weight: 600;
                    letter-spacing: 0.8px;
                    color: $heading-color;

                    &:hover {
                        text-decoration: underline;
                    }
                }
            }
        }
    }

    .order-detail-bottom-wrap {
        .heading-order-table {
            font-size: 16px !important;
            line-height: 20px !important;
            letter-spacing: 0.8px;
            color: $black-64 !important;
            font-weight: 600 !important;
        }
    }

    .download-order {
        font-size: 12px;
        line-height: 22px;
        letter-spacing: 0.8px;
        font-weight: 600;
        color: $heading-color;
        cursor: pointer;

        @media only screen and (max-width:767px) {
            display: block;
            text-align: right;
            margin: 8px 0 0;
        }

        img {
            margin-right: 4px;
        }

        &:hover {
            text-decoration: underline;
        }
    }

    .bulk-order-outer-wrap {
        @media only screen and (max-width:509px) {
            .card-header {
                height: auto !important;
                padding: 15px 15px 10px !important;

                .title_holder {
                    display: block;
                    height: auto !important;
                }

                .download-order {
                    float: left;
                }
            }
        }
    }

    .bulk-order-wrap {
        max-width: 590px;
        margin: 26px auto 0;

        @media only screen and (max-width:767px) {
            margin-top: 0;
        }

        .bo-top-text {
            font-size: 14px;
            line-height: 26px;
            letter-spacing: 0.2px;
            font-weight: 500;
            color: $black-64;
        }

        .upload-heading {
            font-size: 18px;
            line-height: 26px;
            font-weight: 600;
            color: $heading-color;
            margin-top: 22px;
        }

        .bo-pink-Box {
            padding: 17px 12px 17px 17px;
            margin-top: 21px;

            .top-line {
                display: flex;
                justify-content: space-between;
                font-size: 14px;
                line-height: 20px;
                color: $black-3d;
                font-weight: 500;
                align-items: center;
            }

            .download-report {
                font-size: 13px;
                color: #ed2c2b;
                font-weight: 500;
                line-height: 16px;
                cursor: pointer;

                @media only screen and (max-width:767px) {
                    font-size: 11px;
                    width: 130px;
                }

                img {
                    margin-right: 5px;
                }

                &:hover {
                    text-decoration: underline;
                }
            }

            .bottom-text-box {
                font-size: 12px;
                line-height: 20px;
                color: $black-3d;
                margin-top: 9px;
                font-weight: 500;

                ul {
                    padding: 0;
                    margin: 0;
                    list-style: none;

                    li {
                        position: relative;

                        &:before {
                            content: '-';
                            margin-right: 5px;
                        }
                    }
                }
            }
        }

        .file-drop {
            text-align: center;
            position: relative;
            height: 220px;
            margin-top: 10px;

            .file-drop-target {
                border: 1px dashed #b9b9b9;
                position: absolute;
                top: 0;
                left: 0;
                height: 100%;
                width: 100%;
                display: flex;
                flex-direction: column;
                align-items: center;
                justify-content: center;
                align-content: center;
                text-align: center;

                &.file-drop-dragging-over-frame {
                    border-color: #007bff;
                    box-shadow: 0 0 5px 1px #ccc;

                }
            }

            .drop-text {
                font-size: 14px;
                font-weight: 600;
                line-height: 18px;
                color: $black-64;
                display: block;
            }

            .max-size {
                font-size: 11px;
            }

            .file-upload {
                label {
                    background-color: $black-64;
                    color: #ffffff !important;
                    margin: 15px 0 0;
                    width: 100px;
                    padding: 4px 0;
                    font-size: 14px;
                    letter-spacing: 1px !important;
                    cursor: pointer;

                    &:hover {
                        opacity: 0.9;
                    }
                }
            }
        }

        .upload-icon {
            margin-bottom: 19px;
        }
    }
}

.export-order-list {
    text-align: right;
    margin-top: 15px;
    margin-bottom: 10px;

    a {
        font-size: 12px;
        letter-spacing: 0.8px;
        line-height: 22px;
        font-weight: 600;
        color: #0069a7;
        position: relative;

        &:hover {
            text-decoration: underline;
        }

        img {
            position: relative;
            top: -2px;
            margin-right: 5px;
        }
    }
}

.export-order-list {
    text-align: right;
    margin-top: 15px;

    span {
        font-size: 12px;
        letter-spacing: 0.8px;
        line-height: 22px;
        font-weight: 600;
        color: #0069a7;
        position: relative;
        cursor: pointer;

        &:hover {
            text-decoration: underline;
        }

        img {
            position: relative;
            top: -2px;
            margin-right: 5px;
        }
    }
}

@media only screen and (max-width:'1200px') {
    .export-order-list {
        text-align: left;
    }
}

// location blocks
.location-blocks {
    display: flex;
    position: relative;

    @media only screen and (max-width:767px) {
        flex-wrap: wrap;
    }

    .location-name,
    .location-shipping,
    .location-billing,
    .location-btns {
        width: 23%;
        font-size: 13px;
        line-height: 26px;
        letter-spacing: 0.2px;
        color: $black-64;
        font-weight: 500;
    }

    .location-name {
        width: 31%;

        @media only screen and (min-width:768px) and (max-width:918px) {
            width: 26% !important;
        }

        @media only screen and (max-width:767px) {
            width: 100%;
            margin-bottom: 20px;
        }
    }

    .location-shipping {
        width: 28%;

        @media only screen and (max-width:767px) {
            width: 35%;
        }

        @media only screen and (max-width:600px) {
            width: 100%;
            margin-bottom: 20px;
        }
    }

    .location-billing {
        width: 25%;

        @media only screen and (max-width:767px) {
            width: 35%;
        }

        @media only screen and (max-width:600px) {
            width: 100%;
        }
    }

    .location-contact {
        width: 26%;

        @media only screen and (max-width:600px) {
            width: 100%;
            margin-top: 20px;
        }
    }

    .location-btns {
        width: 4%;

        @media only screen and (max-width:767px) {
            position: absolute;
            right: 0;
            top: 0;
            width: 55px;
        }

        .location-head {
            display: flex;
            flex-direction: column;
        }

        span {
            cursor: pointer;

            &:first-child {
                // margin-right: 19px;
                margin-bottom: 20px;
            }
        }
    }

    .location-subname {
        font-weight: 600;
        line-height: 20px;
        font-size: 13px;
        letter-spacing: 0.65px;
    }

    .location-subname,
    .location-subtext {
        margin-top: 12px;
    }

    .location-primary {
        font-size: 12px;
        line-height: 24px;
        color: #3d3839;
        background-color: #e1f3dc;
        border-radius: 12px;
        display: inline-block;
        padding: 0 13px;
        margin-left: 12px;
        font-weight: normal;
    }

    .location-corporate {
        font-size: 12px;
        line-height: 24px;
        color: #ffffff;
        background-color: #3b82f6;
        border-radius: 12px;
        display: inline-block;
        padding: 0 13px;
        margin-left: 12px;
        font-weight: normal;
    }
    .location-customer {
        @extend .location-corporate;
        color: #ffffff;
        background-color: #f59e0b;
    }
    

    .location-head {
        font-size: 16px;
        line-height: 20px;
        letter-spacing: 0.8px;
        font-weight: 600;
        color: $black-64;
    }
}

.new-location-btn {
    float: right !important;
}

// location blocks
.card-table {
    .order-detail-table {
        @media only screen and (max-width:767px) {
            table {
                width: 750px;
            }
        }

        th {
            &:nth-child(5) {
                text-align: center;
            }

            &:last-child {
                text-align: right;
            }
        }

        td {
            font-size: 14px;
            line-height: 20px;
            position: relative;

            &.total-amount {
                vertical-align: top !important;
                text-align: right;

                .total-amount-inner {
                    color: $black-64;
                    font-size: 14px;
                    line-height: 28px;
                    font-weight: 500;

                    .final-amt {
                        font-weight: bold;
                        font-size: 15px;
                        color: black;
                    }

                    span {
                        display: block;
                    }
                }
            }

            &.total-text {
                vertical-align: top !important;

                .total-text-inner {
                    width: 80px;
                    text-align: left;
                    color: $black-3d;
                    font-size: 15px;
                    line-height: 28px;
                    font-weight: 500;

                    .final-total {
                        font-weight: bold;
                        font-size: 15px;
                        color: black;
                    }

                    span {
                        display: block;
                    }
                }

                &:after {
                    display: none;
                }
            }

            &:after {
                content: '';
                border-right: 1px solid #f0f0f0;
                top: 21%;
                right: 5%;
                position: absolute;
                height: 58%;
            }

            .premium {
                // font-size: 11px;
                // letter-spacing: 0.92px;
                // color: $black-64;
                // opacity: 0.8;
                // display: block;
                color: #645a5b;
                font-size: 12px;
                font-stretch: normal;
                font-style: normal;
                font-weight: 500;
                letter-spacing: .92px;
                line-height: normal;
                word-break: break-word;
            }

            .premium {
                line-height: 18px;
                display: block;
            }

            &:first-child {
                a {
                    color: $heading-color;
                }
            }

            &:nth-child(5) {
                text-align: center;
            }

            &:last-child {
                text-align: right;

                &:after {
                    display: none;
                }
            }
        }
    }
}

.account-breadcrumb-wrap {
    font-size: 12px;
    font-weight: 600;
    color: $black-64;
    line-height: 26px;
    margin: 15px auto 18px;
    max-width: 100%;
    box-sizing: border-box;

    @media only screen and (max-width:990px) {
        padding-left: 15px;
    }

    ul {
        list-style: none;
        margin: 0;
        padding: 0;
        display: flex;
        justify-content: flex-start;

        li {
            a {
                color: $black-64;
                position: relative;

                &:hover {
                    text-decoration: underline;
                }

                &:after {
                    content: '/';
                    margin: 0 3px;
                }
            }
        }
    }
}

.pending-box {
    margin-bottom: 20px;
    display: flex;
    font-size: 14px;
    font-weight: 500;
    justify-content: space-between;
    align-items: center;
    padding: 17px 15px 11px 17px;

    .pending-left {
        p {
            text-align: left;
            margin: 0 0 6px 0;

            &:first-child {
                line-height: 20px;
            }

            &:last-child {
                letter-spacing: 1px;
                font-size: 12px;
                line-height: normal;
            }

            .pending-left-text {
                color: $black-64;
                font-weight: 500;
            }

            .pending-right-text {
                color: $button-red;
                font-weight: 800;
                margin-left: 5px;
            }
        }
    }

    .pending-right {
        button {
            font-size: 13px;
            font-weight: 500;
            color: $button-red;
            line-height: 16px;
            border: 0;
            outline: none;
            background: none;
        }
    }

}

@media only screen and (max-width:480px) {
    .pending-box {
        flex-direction: column !important;
    }

}

.modal-pending {
    .modal-dialog {
        max-width: 704px;
        margin-top: 50px;

        .modal-header {
            justify-content: center;
            width: 100%;
            position: relative;
            font-size: 20px;
            line-height: 27px;
            letter-spacing: 1px;
            color: $black-3d;
            text-transform: uppercase;
            margin-bottom: 14px;
            padding-top: 34px;

            button {
                &.btn-close {
                    position: absolute;
                    right: 8px;
                    top: 10px;
                    width: 22px;
                    height: 22px;
                    background: none;
                    border: none;
                    outline: none;

                    &:before {
                        content: '';
                        height: 100%;
                        width: 2px;
                        background-color: #000;
                        position: absolute;
                        top: 0;
                        left: 8px;
                        transform: rotate(45deg);
                    }

                    &:after {
                        content: '';
                        height: 100%;
                        width: 2px;
                        background-color: #000;
                        position: absolute;
                        top: 0;
                        right: 11px;
                        transform: rotate(-45deg);
                    }
                }
            }
        }

        .modal-body {
            margin: 0;
        }

        .modal-content {
            height: auto;
            text-align: left;

            .right-body-account {
                padding: 0 30px 30px;

                @media only screen and (max-width:767px) {
                    padding: 0 15px 15px;
                }

                h6 {
                    font-size: 16px;
                    line-height: 26px;
                    color: $heading-color;
                    font-weight: 600;
                    margin-bottom: 15px;
                    text-align: left;
                }

                .acc-detail-block-outer {
                    margin-bottom: 26px;

                    .account-detail-block {
                        display: flex;
                        justify-content: space-between;
                        margin-bottom: 15px;

                        @media only screen and (max-width:767px) {
                            flex-direction: column;
                        }

                        &:last-child {
                            margin-bottom: 0;
                        }

                        .block-left,
                        .block-right {
                            width: 48%;
                            text-align: left;

                            @media only screen and (max-width:767px) {
                                width: 100%;
                            }

                            .contact-line-1 {
                                margin-bottom: 15px;

                                .bold-label {
                                    color: $black-3d;
                                    font-size: 16px;
                                    margin-bottom: 14px;
                                    font-weight: bold;
                                }

                                &:last-child {
                                    margin-bottom: 0;
                                }
                            }

                            label {
                                font-size: 12px;
                                letter-spacing: 1px;
                                color: $black-64;
                                font-weight: 500;
                                text-transform: uppercase;
                                opacity: 0.8;
                            }

                            .label-value {
                                font-size: 15px;
                                line-height: 20px;
                                color: $black-3d;
                                font-weight: 500;
                            }
                        }

                        @media only screen and (max-width:767px) {
                            .block-left {
                                margin-bottom: 15px;
                            }
                        }
                    }
                }
            }
        }
    }

    .pop-up-cross-btn {
        width: 98%;
        margin-top: 10px;
    }

    .md-close-icon {
        float: right;
        cursor: pointer;
    }

    .disable-warehouse {
        font-size: 20px;
        font-weight: 600;
        padding-top: 20px;
    }

    &.modal-location-add {
        .modal-dialog {
            max-width: 826px;

            .modal-header {
                .modal-title {
                    font-size: 20px;
                    letter-spacing: 1px;
                    font-weight: 600;
                    line-height: 27px;
                }
            }

            .modal-body {
                text-align: left;

                @media only screen and (max-width:599px) {
                    padding: 0 15px 15px !important;
                }

                &.right-body-account {
                    padding-left: 28px;
                    padding-right: 28px;
                }

                .location-modal-top {
                    margin-bottom: 25px;
                }

                .shipping-billing-wrap {
                    display: flex;
                    justify-content: space-between;

                    @media only screen and (max-width:599px) {
                        flex-wrap: wrap;
                    }

                    .shipping-left,
                    .billing-right {
                        width: 48.052%;

                        @media only screen and (max-width:599px) {
                            width: 100%;
                        }

                        .location-modal-inner-head {
                            font-size: 16px;
                            line-height: 26px;
                            color: $black-3d;
                            font-weight: 600;
                            margin-bottom: 13px;
                        }
                    }

                    @media only screen and (max-width:599px) {
                        .billing-right {
                            margin-top: 25px;
                        }
                    }

                    .sb-line1 {
                        margin-bottom: 16px;

                        &.sb-line2 {
                            display: flex;
                            justify-content: space-between;
                            margin-bottom: 0;

                            .sb-inner-left,
                            .sb-inner-right {
                                width: 48.5%;
                            }
                        }
                    }

                    @media only screen and (max-width:864px) {
                        .city-zip-ipadview {
                            flex-direction: column;
                        }

                        .city-zip-width-ipadview {
                            width: 100% !important;
                        }

                        .lable-mobview {
                            margin-top: 16px;
                        }
                    }
                }

                .checkbox-submit-wrap {
                    display: flex;
                    // justify-content: space-between;
                    gap: 20px;
                    align-items: center;
                    margin-top: 20px;

                    @media only screen and (max-width:599px) {
                        flex-wrap: wrap;

                        .button-right {
                            width: 100%;
                            margin-top: 15px;
                        }
                    }
                }

                .detail-btns {
                    .red_btn {
                        font-weight: 500;
                    }
                }
            }
        }
    }

    &.modal-location-status {
        .modal-dialog {
            max-width: 604px;

            .modal-header {
                .modal-title {
                    font-size: 20px;
                    letter-spacing: 1px;
                    font-weight: 600;
                    line-height: 27px;
                }
            }

            .modal-body {
                padding: 0 57px 30px;

                @media only screen and (max-width:599px) {
                    padding: 0 15px 15px;
                }

                .pending-box {
                    margin-bottom: 31px;
                }

                .location-blocks {
                    justify-content: space-between;
                    flex-wrap: wrap;

                    .location-name {
                        width: 100%;
                        font-size: 15px;
                        line-height: 20px;
                        letter-spacing: 0.75px;
                        color: $black-64;
                        font-weight: 600;
                        margin-bottom: 15px;
                    }

                    .location-shipping,
                    .location-billing {
                        width: 42%;

                        @media only screen and (max-width:599px) {
                            width: 100%;
                        }
                    }
                }
            }
        }

    }
}

.location-name-popup {
    color: #645a5b;
    font-size: 15px;
    font-weight: 600;
    letter-spacing: .75px;
    line-height: 20px;
    margin-bottom: 15px;
    width: 100%
}

.modal-fields {
    label {
        font-size: 14px;
        line-height: 26px;
        color: $black-64;
        font-weight: 600;
        opacity: 1;
        margin-bottom: 6px;
    }

    .label-value {
        font-size: 13px;
        line-height: 26px;
        color: $black-3d;
        font-weight: 500;
    }

    .input-style-detail {
        width: 100%;
        height: 45px;
        padding-left: 15px;
        padding-right: 5px;
        font-size: 13px;
        line-height: 20px;
        color: $black-3d;
        font-weight: 500;
        border: 1px solid $black-64;

        &:focus-visible {
            outline: none;
        }
    }

    .mobile--input {
        width: 100%;
        color: $black-3d;
        font-weight: 600;
        font-size: 14px;
        opacity: 1;
        border: 1px solid $black-64;
        cursor: pointer;

        &:focus-visible {
            outline: none;
            cursor: pointer;
        }

        &.select_input {
            background-position-x: 96%;
            cursor: pointer;
        }
    }

    .flex-checkbox {
        align-items: center;
    }

    .checkbox-name {
        font-size: 13px;
        font-weight: 500;
        line-height: 26px;
        color: $black-64;
        margin-bottom: 0;
        margin-left: 0;
        display: block;
        position: relative;
        padding-left: 23px;
        cursor: pointer;
        user-select: none;

        .modal-checkbox {
            position: absolute;
            opacity: 0;
            cursor: pointer;
            height: 0;
            width: 0;
        }

        .checkmark {
            position: absolute;
            top: 5px;
            left: 0;
            height: 16px;
            width: 16px;
            background-color: #fff;
            border: 1px solid $heading-color;

            &:after {
                content: "";
                position: absolute;
                display: none;
                left: 5px;
                top: 1px;
                width: 5px;
                height: 9px;
                border: solid $heading-color;
                border-width: 0 2px 2px 0;
                -webkit-transform: rotate(45deg);
                -ms-transform: rotate(45deg);
                transform: rotate(45deg);
            }
        }

        .modal-checkbox:checked~.checkmark {
            background-color: #fff;
            border: 1px solid $heading-color;
        }

        .modal-checkbox:checked~.checkmark:after {
            display: block;
        }
    }
}

.pink-color {
    background-color: $pink-bg;
}

.green-color {
    background-color: #e6f8e6;
}

.blue-color {
    color: $heading-color !important;
}

// edit delte btns
.delete-btn {
    transition: all 0.3s ease-in;

    &:hover {
        svg {
            g {
                fill: $heading-color;
            }
        }
    }
}

.edit-btn {
    transition: all 0.3s ease-in;

    &:hover {
        svg {
            g {
                stroke: $heading-color;
            }
        }
    }
}

.hide_button {
    display: none !important;
}

.show_button {
    display: block !important;
}

.modal-dialog {
    .modal-body {
        text-align: left;
    }
}

.pdf-icon-wrap {
    display: flex;
    margin-top: 5px;

    .pdf-icon {
        border: 1px solid #807879;
        padding: 12px 11px;
        margin: 0 15px 0 0;
        position: relative;

        .pdf-cross {
            position: absolute;
            right: -6px;
            top: -7px;
            cursor: pointer;
        }
    }

    .upload-btn {
        .choose--btn {
            margin-top: 0;
            border: 1px solid #d3d9e3;
        }
    }
}

.pop-up-location-shipping {
    width: 35% !important;
}

.pop-up-location-billing {
    width: 30% !important;
}

.pop-up-location-contact {
    width: 30% !important;
}

.info-icon {
    position: relative;
    display: inline-block;
    margin-left: 3px;
    cursor: pointer;
}

.tooltiptext {
    visibility: hidden;
    width: 150px;
    height: fit-content;
    padding: 11.8px 13px 18px 14px;
    margin: 5px;
    border-radius: 4px;
    box-shadow: 0 0 6px 0 rgba(0, 0, 0, 0.19);
    background-color: #fff;
    color: #645a5b;
    text-align: center;
    border-radius: 6px;
    padding: 5px 0;
    position: absolute;
    z-index: 1;
    bottom: 125%;
    left: 50%;
    margin-left: -60px;
    opacity: 0;
    transition: opacity 0.3s;
    font-size: 11px;
    line-height: 1.36;
    letter-spacing: normal;
    color: #645a5b;
}

.tooltiptext::after {
    content: "";
    position: absolute;
    top: 100%;
    left: 40%;
    margin-left: -5px;
    border-width: 9px;
    border-style: solid;
    border-color: rgb(240, 236, 236) transparent transparent transparent;
    box-shadow: 0 0 5px 0.9 rgba(0, 0, 0, 0.5);
}

.info-icon:hover .tooltiptext {
    visibility: visible;
    opacity: 1;
}

.choose--btn {
    width: 158px;
    height: 45px;
    border-radius: 3px;
    border: solid 1px #d3d9e3;
    background-color: #f2f3f5;
    font-size: 14px;
    font-weight: 600;
    line-height: 1.86;
    margin-top: 5px;
    text-align: center;
    color: #3d3839;
    border: none;
}

.choose--btn:focus {
    outline: none;
}

.choose-hover {
    &:hover {
        background-color: #d2d5da;
    }
}

.label {
    font-size: 14px;
    font-weight: 600;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.86;
    letter-spacing: normal;
    color: #645a5b;
    margin-bottom: 2px;
}

@media only screen and (max-width:600px) {
    .pop-up-location-shipping {
        width: 100% !important;
    }

    .pop-up-location-billing {
        width: 100% !important;
    }

    .pop-up-location-contact {
        width: 100% !important;
    }

    .new-billing {
        margin-top: 0px !important;
    }
}

.sb-first-name {
    margin-bottom: 16px !important;
}

.new-location-contact {
    margin-top: 20px;
    margin-bottom: 10px;
}

@media only screen and (max-width:920px) {
    .modal-dialog {
        width: 90%;
        margin-left: auto;
        margin-right: auto;
    }
}

.tooltipdata {
    display: flex;
    justify-content: space-between;
    padding: 5px 10px;
}

.tooltip-innerdata {
    max-height: 200px;
    overflow-y: scroll;
    display: flex;
    flex-direction: column;
    align-items: stretch;
}

.partid-qty-div {
    display: flex;
    justify-content: space-between;
    padding: 5px 10px;
}

.part--id {
    color: #645a5b;
    letter-spacing: 0.5px;
    font-size: 13px;
    font-weight: bold;
}

.partid-qty-div span {
    font-size: 14px;
    font-weight: normal;
    letter-spacing: 0.4px;
    color: #3d3839;
}

.dropdown {
    .customer-select {
        padding: 0px 10px !important;
        border-radius: 2px 0 0 2px !important;
        border: solid 1px #d0cece !important;
        background-color: #f9f9f9 !important;
        color: #606060;
        height: 34px;
        font-size: 14px;

        // width: 150px;
        span {
            font-weight: 500;
        }

        .customer-select-order {
            height: 40px !important;
        }
    }

    search-account-order {
        height: 40px !important;
    }
}

.downarrow-ordertable {
    padding-left: 2px;
}

.order-dropitem {
    font-size: 14px;
    color: #606060;
}

.pop-up-height {
    height: auto !important;
}

.order-yes {
    width: 78px;
    height: 35px;
    border-radius: 3px;
    background-color: #d54242;
    font-size: 14px;
    font-weight: 500;
    color: white;
    border: none
}

.order-no {
    background: #645a5b;
    margin-left: 20px;
}

.order-btn-main-div {
    display: flex;
    justify-content: center;
    margin: 0 0 26px 0;
}

.order-return-heading {
    font-size: 18px !important;
    font-weight: 500 !important;
    margin-bottom: 26px;
    display: flex;
    justify-content: center;
    padding-top: 25px;
}

.new-close-img {
    float: right;
    cursor: pointer;
}

.return-modal {
    padding: 10px !important;
}

.order-confirm-box {
    width: 90%;
    justify-content: center;
    margin: 0 auto;
    margin-bottom: 38px;
    margin-top: 28px;
}

@media screen and (max-width : 480px) {
    .date-main-div {
        width: 100%;
    }

    .date-field {
        width: 100%;
    }

    .r_date_left {
        width: 100% !important;
    }

    .r_date_right {
        width: 100% !important;
    }

    .filter_date {
        row-gap: 7px;
    }
}

.pop-up-tick {
    display: flex;
    justify-content: center;
    margin-top: 5px;
}

.date-field {
    width: 182px;
    height: 34px;
}

@media only screen and (max-width: 480px) {
    .date-field {
        width: 100% !important;
    }
}

.filter---by {
    width: 65px;
    margin-left: 12px;
}

.date-filter-div {
    display: flex;
}

.fromdate {
    margin-right: 10px;
}

@media only screen and (max-width: 580px) {
    .date-filter-div {
        flex-direction: column;
    }

    .date-field {
        margin: 10px 0;
    }

    .filter---by {
        width: auto;
    }
}

.react-datepicker__year-read-view--down-arrow {
    top: 3px !important;
}

.react-datepicker__year-read-view--down-arrow,
.react-datepicker__month-read-view--down-arrow,
.react-datepicker__month-year-read-view--down-arrow {
    top: 4.5px !important;
}

@media only screen and (max-width: 480px) {
    .fromdate {
        margin-right: 0px !important;
    }

    #product-nav-div {
        flex-direction: column;
    }

    .product-lower-input {
        margin-left: 4px;
    }

}

@media only screen and (max-width: 848px) {
    .products-view__pagination {
        flex-direction: column;
        align-items: flex-start;
    }
}

.customer-select-order {
    height: 40px !important;
}

.search-account-order {
    height: 40px !important;
}

.date-field-order {
    height: 38px !important;
}

@media only screen and (max-width: 990px) {
    #filter_date .react-datepicker-popper {
        transform: none !important;
    }
}

//   .order-yes {
//     width: 78px;
//     height: 35px;
//     border-radius: 3px;
//     box-shadow: 0 1px 2px 0 rgb(74 19 19 / 5%);
//     background-color: #d54242;
//     font-size: 14px;
//     font-weight: 500;
//     color: white;
//     border: none;
//   }


//Bulk Upload via CSV


.download-csv-heading {
    color: #0069a7;
}

.bulk-upload-btn {
    padding: 8px 14px;
    font-size: 14px;
    font-weight: 500;
    color: #645a5b;
    border-radius: 3px;
    box-shadow: 0 1px 2px 0 rgba(74, 19, 19, 0.05);
    border: solid 1px #d0cece;
    background-color: var(--white);
}

.bulk-upload-modal-container {
    .cancel-btn {
        background-color: #655b5c;
        border: none;
        padding: 8px 20px;
        color: white;
        border-radius: 5px;
    }

    .upload-btn {
        background-color: #0069a7;
        border: none;
        padding: 8px 20px;
        color: white;
        border-radius: 5px;
        opacity: 0.3;
    }

    .upload-btn-active {
        opacity: 1;
    }

    .replace-btn {
        border: none;
        background-color: #ed2c2b;
        padding: 10px;
    }

    .bx-file {
        font-size: 18px;
    }

    .error-warning-container {
        background-color: #fde1e1;
        padding: 10px;
        font-size: 12px;

        .bxs-info-circle {
            font-size: 18px;
            color: #d54242;
        }
    }
}

.drag-and-drop {
    width: 100%;
    height: 190px !important;
    min-height: 0;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    border: dotted 2px #b9b9b9;
    margin-top: 10px;
}

.drag-browse-btn {
    border-radius: 2px;
    background-color: #645a5b;
    font-size: 14px;
    font-weight: 600;
    border: none;
    color: white;
    width: 100px;
    height: 34px;
}

.drag-and-drop-file {
    font-family: Montserrat;
    font-size: 14px;
    font-weight: 600;
    line-height: 1.29;
    letter-spacing: normal;
    color: #645a5b;
    margin: 10px 0;
}

.replace-btn {
    border: none;
    background-color: white;
    padding: 7px 10px;
    font-size: 14px;
    font-weight: 600;
    border-radius: 2px;
    margin-left: 15px;
    border: 1px solid gray;
}

.upload-img {
    margin: 0 auto;
    display: block;
}

.new-upload-btn {
    margin: 0 auto !important;
    display: block !important;
}

.shipment-table {
    background-color: white;
}

.order--highlight {
    background: #e1f3dc;
    width: fit-content;
    padding: 5px 12px;
    border-radius: 17px;
}

.error--box {
    background-color: #fde1e1;
    padding: 10px;
    font-size: 14px;
    display: flex;
}

.info-bulk-icon {
    font-size: 20px;
    color: red;
}

.cursor-pointer {
    cursor: pointer;
}