.main-static-header {
  width: 100%;
  height: 80px;
  display: flex;
  // flex-direction: column;
  justify-content: center;
  border: solid 1px rgba(227, 227, 227, 0.57);
  background-color: #fff;
  @media only screen and (max-width: 480px) {
    justify-content: center;
  }
}
.sign-link {
  color: #0069a7!important;
  &:hover{
    color: #ed2c2b !important;
  }
}
.register-header {
  margin-right: 20px;
}
.static-header {
  max-width: 950px;
  width: 100%;
  height: 80px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  @media only screen and (max-width: 1024px) {
    width: 800px;
  }
  @media only screen and (max-width: 780px) {
    justify-content: center;
  }
}
.qls-img {
  @media only screen and (max-width: 480px) {
  }
}
.new-qls {
  font-size: 15px;
  font-weight: 600;
  font-stretch: normal;
  font-style: normal;
  letter-spacing: normal;
  color: #645a5b;
}
.header-register {
  color: #0069a7;
}
.already-account {
  font-size: 15px;
  font-weight: 600;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.87;
  letter-spacing: normal;
  text-align: right;
  color: #0069a7;
  @media only screen and (max-width: 780px) {
    display: none;
  }
}

/*
  // .header
  */
:root {
  --white: #fff;
}

.header__navbar-main {
  height: 80px;
  width: 100%;
  background-color: #fff;
}

.header_navbar_logo__mobile {
  height: 68px;
  width: 100% !important;
  background-color: var(--white);
}

.header_navbar {
  width: 100% !important;
  height: 60px;
  background-color: #f2f2f2;
}

.header_navbar__mobile {
  width: 100% !important;
  height: 106px;
  background-color: #f2f2f2;
}

.nav-links a,
.products {
    height: 100%;
  text-decoration: none;
  font-size: 14px;
  font-weight: 600;
  color: #655b5c !important;
  overflow-y: auto;
  &:hover{
    color: black;
  }
}
.Path-12 {
  width: 1px;
  height: 25px;
  margin: auto 20px;
  border: solid 1px #c9c9c9;
}

.Application-Search {
  width: auto;
  height: 34px;
  border-radius: 2px;
  font-size: 12px;
  font-weight: 600;
  letter-spacing: 1px;
  color: #fff;
  background-color: #ed2c2b;
  border: none !important;
  &:hover{
    background-color:  #801919;
  }
}

.Application-Search svg {
  vertical-align: -0.1875em;
}

.Login-Register {
  font-size: 13px;
  font-weight: 600;
  color: #645a5b;
  display: flex;
}

.Path-2 {
  width: 1px;
  height: 38px;
  margin: 1px 31px 5px;
  border-style: solid;
  border-width: 1px;
  border-image-source: linear-gradient(to bottom, #fff 1%, #bebebe 49%, #fff);
  border-image-slice: 1;
}


.login_header {
  display: flex;
  justify-content: space-evenly;
  flex-direction: row;
  background-color: #f7f8fa;
}

.login_mobileheader {
  background-color: #f7f8fa;
}

.has-search-right {
  position: relative;
  z-index: 2;
}

.has-search-right .form-control {
  padding-right: 2.375rem;
}

.has-search-right .form-control-feedback {
  position: absolute;
  top: 3px;
  right: 0;
  z-index: 2;
  display: block;
  width: 2.375rem;
  height: 2.375rem;
  line-height: 2.375rem;
  text-align: center;
  color: #aaa;
}

.wrapper-search-mobile {
  ul{
    padding: 0px;
    list-style: none;
    background: #ffffff;
    z-index: 2;
    width: 100%;
    max-height: 300px;
    overflow: auto;
    box-shadow: var(--mdc-fab-box-shadow, 0px 3px 5px -1px rgba(0, 0, 0, 0.2), 0px 6px 10px 0px rgba(0, 0, 0, 0.14), 0px 1px 18px 0px rgba(0, 0, 0, 0.12));
    li{
      padding: 12px 8px;
      border-bottom: solid 1px rgba(100, 88, 90, 0.4);
      font-size: 15px;
      &:hover{
        background: #f2f2f2;
        cursor: pointer;
      }
      &.active{
        background: #f2f2f2;
      }
    }
  }
}

.search-app {
  margin: 0 auto;
  height: 45px;
  padding: 0 20px;
  outline: none;
  border-radius: 2px;
  // box-shadow: 0 0 3px 0 rgba(0, 0, 0, 0.04);
  border: solid 1px rgba(100, 88, 90, 0.4);
  background-color: #fff;
  font-size: 15px;
  color: #606060;
  width: 600px;
  &.border-none{
    border: none;
  }
  &::placeholder{
    font-size: 15px;
    color: #606060;
  }
  &:focus {
    border: solid 1px rgba(100, 88, 90, 0.4);
    transition: 0.35s ease;
    color: #606060;
    outline: none;
    &::-webkit-input-placeholder {
      transition: opacity 0.45s ease;
      opacity: 0;
    }
    &::-moz-placeholder {
      transition: opacity 0.45s ease;
      opacity: 0;
    }
    &:-ms-placeholder {
      transition: opacity 0.45s ease;
      opacity: 0;
    }
  }
  ul{
    padding: 0px;
    list-style: none;
    background: #ffffff;
    z-index: 2;
    width: 600px;
    max-height: 300px;
    overflow: auto;
    box-shadow: var(--mdc-fab-box-shadow, 0px 3px 5px -1px rgba(0, 0, 0, 0.2), 0px 6px 10px 0px rgba(0, 0, 0, 0.14), 0px 1px 18px 0px rgba(0, 0, 0, 0.12));
    li{
      padding: 15px 10px;
      border-bottom: solid 1px rgba(100, 88, 90, 0.4);
      &:hover{
        background: #f2f2f2;
        cursor: pointer;
      }
      &.active{
        background: #f2f2f2;
      }
    }
  }
}

.default-hover{
  &:hover{
    color: #ed2c2b;
  }
}

.search-app__mobile {
  width: 100%;
  padding: 0 10px;
  font-size: 15px;
}

.Application-Search__mobile {
  width: 100%;
  font-size: 10px;
  margin-top: -10px;
}
.ql-logo-header {
 
  img{ width: auto;
    height: 38px;}
  @media only screen and (max-width: 1024px) and (min-width: 981px) {
    margin-left: 35px;
  }
}
.header__login{
  color: #645a5b;
  margin: 0 5px;
}
.header-name-div{
  display: flex;
  flex-direction: column;
}
.hello-rich{  
  font-size: 13px;
  font-weight: 600;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.23;
  letter-spacing: 0.87px;
  color: #645a5b;
  position: relative;
  cursor: pointer;
  margin-left: 10px;
}
.header-dropbox{
  top:75px;
  width: 229px;
  background-color: var(--white);
  font-size: 14px;
  font-weight: 500;
  font-stretch: normal;
  font-style: normal;
  letter-spacing: normal;
  color: #645a5b;
  position: absolute;
  z-index: 2;
  padding: 17px;

}
.header-hr{
  margin: 10px 0;
}

.header-dropbox-order{
  margin: 8px 0;
}
.hover-eff{
  padding: 5px 10px;
  cursor: pointer;
}
.hover-eff:hover {
  background-color: #f2f2f2;
}
.header-search-btn :hover{
background-color: #606060;
}
.header-cart-span {
  display: flex;
  align-items: center;
  &:hover{
   background-color: #f2f2f2;
   padding: 10px 0px;
  }
}
.header-cart{
  &:hover{
    color: white;
  }
}
.header-link{
  &:hover{
    color:black !important;
  }
}

.location-main-div{
  display: flex;
  color: #262626;
  &:hover {
    color: #262626;
  }
}
.header-location{ 
  font-size: 11px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: 0.56px;
  color: #645a5b;
}
.header-loction-div{
  display: flex;
  flex-direction: column;
  margin-left: 8px;
}
.location-drop{
  position: absolute;
  margin-top: 20px;
}
.logout-mobile{
  margin-bottom: 0 !important;
}
.header--location{
  max-width: 220px;
  .header-dropbox{
    height: 0;
    padding: 0 17px;
    overflow: hidden;
    transition: all 0.25s ease-in-out;
  }
  &:hover{
    .header-dropbox{
      height: fit-content;
      padding: 17px;
      box-shadow: 0 5px 9px 3px rgba(0, 0, 0, 0.09);
    }
   
  }
}

// .no-hover:hover {
//   .header-dropbox {
//     height: 0;
//     padding: 0 17px;
//     box-shadow: none;
//   }
// }

.header__username{
  .rounded-circle{
    line-height: normal;
  }
  .header-dropbox{
    height: 0;
    padding: 0 17px;
    overflow: hidden;
    transition: all 0.25s ease-in-out;
  }
  &:hover{
    .header-dropbox{
      height: fit-content;
      padding: 17px;
      box-shadow: 0 5px 9px 3px rgba(0, 0, 0, 0.09);
    }
   
  }
}
.resource-link{
  padding: 18px 0 18px 0;
  .header-dropbox{
    top: 60px;
    height: 0;
    padding: 0 17px;
    overflow: hidden;
    transition: all 0.25s ease-in-out;
  }
  &:hover{
    .header-dropbox{
      top: 60px;
      height: fit-content;
      padding: 17px;
      box-shadow: 0 5px 9px 3px rgba(0, 0, 0, 0.09);
    }
  }
}
@media only screen and (max-width:'1280px') and (min-width:'1135px') {
  .search-app
  {
    width: 350px;
  }
}
@media only screen and (max-width:'1370px') and (min-width:'1135px') {
  .search-app
  {
    width: 370px;
  }
}
.header-user-icon{
  font-size: 25px;
  color: #645a5b;
  margin: 0 5px;
}

.header-bars-logo{
  color: #645a5b;
}

.header-location-container{
  width: 150px;
  overflow: hidden;
  word-break: break-word;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
}

.header-location-logo{
  font-size: 25px;
}
.product__categories{
  &:hover{
    color: black !important;
  }
}
.mobile__menu--div{
  display: none;
}
@media only screen and (max-width:'1135px') {
 .desktop__header{
   display: none;
 } 
 .mobile__menu--div{
  display: block !important;
}
}

.total-items-count{
  background-color: #ed2c2b;
    min-height: 20px;
    min-width: 20px;
    padding: 3px;
    color: white;
    font-size: 10px;
    line-height: normal;
    position: absolute;
    top: -12px;
    right: 9px;
    font-weight: bold;
    display: flex;
    align-items: center;
    justify-content: center;

  @media only screen and (max-width:'768px') {
    right: -10px;
    min-height: 20px;
    min-width: 20px;
  }
}
